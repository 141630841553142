<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="580px" :before-close="handleClose"
            :fullscreen="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
                <el-form-item label="审核：" prop="action">
                    <el-radio-group v-model="formData.action">
                        <el-radio :label="20">同意</el-radio>
                        <el-radio :label="30">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="未通过原因：" prop="audit_remarks">
                    <el-input :disabled="!isaudit" type="textarea" v-model.trim="formData.audit_remarks" clearable
                        placeholder="请输入" style="width:100%"></el-input>
                </el-form-item> -->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { mmgjShopAudit } from "@/api/api"
export default {
    emits: ['refreshData', 'closed'],
    name: "addModuleShopAudit",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            peopleList: [],
            formData: {
                action: 10,
                audit_remarks: "",
            },
            rules: {
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
                if (this.formData.audit_status == 20 || this.formData.audit_status == 30) {
                    this.formData.action = this.formData.audit_status
                } else {
                    this.formData.action = 20
                }
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let netData = {
                        'action': this.formData.action,
                        'audit_remarks': this.formData.audit_remarks,
                        'id': this.formData.id
                    }
                    mmgjShopAudit(netData).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.$emit('refreshData')
                            this.handleClose()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                }
            })
        }

    }
}
</script>

