<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="员工名称：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="员工名称" @change="search"
                        style="width:200px"></el-input>
                </el-form-item>
                <el-form-item label="登录账号：">
                    <el-input v-model.trim="formInline.username" maxlength="60" clearable placeholder="登录账号"
                        @change="search" style="width:200px"></el-input>
                </el-form-item>
                <!--                <el-form-item label="权限字符：">-->
                <!--                    <el-input size="small" v-model.trim="formInline.name" maxlength="60" placeholder="权限字符" @change="search" style="width:200px"></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="状态：">
                    <el-select v-model="formInline.is_active" placeholder="请选择" clearable style="width: 120px"
                        @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!--                <el-form-item label="创建时间：">-->
                <!--                    <el-date-picker-->
                <!--                            style="width:350px"-->
                <!--                            v-model="timers"-->
                <!--                            size="small"-->
                <!--                            type="datetimerange"-->
                <!--                            @change="timeChange"-->
                <!--                            range-separator="至"-->
                <!--                            start-placeholder="开始日期"-->
                <!--                            end-placeholder="结束日期">-->
                <!--                    </el-date-picker>-->
                <!--                </el-form-item>-->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button icon="Plus" @click="addAdmin" type="primary"
                        v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tableData" ref="tableref"
                v-loading="loadingPage" style="width: 100%">
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <!--<el-table-column min-width="150" prop="id" label="管理员编号" show-overflow-tooltip></el-table-column>-->
                <el-table-column min-width="120" prop="name" label="管理员名称"></el-table-column>
                <el-table-column min-width="150" prop="username" label="管理员账号"></el-table-column>
                <el-table-column min-width="100" prop="rolekey" label="权限字符">
                    <template #default="scope">
                        <span v-for='(item, index) in scope.row.rolekey' :key="index">
                            {{ (index > 0 && index < scope.row.rolekey.length ? '、' : '') + item }} </span>
                    </template>
                </el-table-column>
                <!--<el-table-column min-width="100" prop="name" label="显示排序"></el-table-column>-->
                <el-table-column min-width="100" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.is_active" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column min-width="150" prop="last_login" label="最近一次登录时间"></el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="120">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-admin ref="addAdminFlag" @refreshData="getData"></add-admin>
    </div>
</template>
<script>
import addAdmin from "./components/addAdmin";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { apiSystemUser, apiSystemUserDelte, apiSystemDisable } from '@/api/api'
export default {
    components: {
        Pagination,
        addAdmin
    },
    name: 'adminManage',
    data() {
        return {
            isFull: false,
            visible: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                search: '',
                status: '',
                page: 1,
                limit: 10,
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            timers: [],
            tableData: []
        }
    },
    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        changeStatus(row) {
            let vm = this
            let flat = row.is_active
            row.is_active = !row.is_active
            let temp_is_allow = 1
            vm.$confirm('确定修改状态吗？', '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
                flat ? row.is_active = true : row.is_active = false
                if (row.is_active) {
                    temp_is_allow = 1
                } else {
                    temp_is_allow = 0
                }
                apiSystemDisable({ id: row.id }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.getData()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        addAdmin() {
            this.$refs.addAdminFlag.addAdminFn(null, '新增')
        },
        handleEdit(row, flag) {
            if (flag == 'edit') {
                this.$refs.addAdminFlag.addAdminFn(row, '编辑')
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的管理员？', {
                    closeOnClickModal: false
                }).then(res => {
                    apiSystemUserDelte({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            apiSystemUser(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }
    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },
    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
