<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="代理商：" prop="belong_agent_id">
                    <el-select v-model="formData.belong_agent_id" placeholder="请选择" clearable filterable style="width: 100%"
                        @change="handleChange">
                        <el-option v-for="item in agentList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="门店名称：" prop="belong_shop">
                    <el-select v-model="formData.belong_shop" placeholder="请选择" clearable filterable style="width: 100%">
                        <el-option v-for="item in shopList" :key="item.id" :label="item.shop_name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { mmgjDeviceEdit, mmgjShop, UsersAgentUsers } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog },
    emits: ['refreshData', 'closed'],
    name: "moduleDevice",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                id: '',
                belong_agent_id: '',
                belong_shop: '',
                bind: 1,
            },
            rules: {
                belong_agent_id: [
                    { required: true, message: '请选择代理商', trigger: 'blur' }
                ],
                belong_shop: [
                    { required: true, message: '请选择门店名称', trigger: 'blur' }
                ],
            },
            agentList: [],
            shopList: [],
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleChange() {
            this.shopList = []
            this.formData.belong_shop = ""
            mmgjShop({ type: 1, page: 1, limit: 999, audit_status: 20, belong_agent_id: this.formData.belong_agent_id }).then(res => {
                if (res.code == 2000) {
                    this.shopList = res.data.data
                }
            })
        },
        getBindData() {
            mmgjShop({ type: 1, page: 1, limit: 999, audit_status: 20 }).then(res => {
                if (res.code == 2000) {
                    this.shopList = res.data.data
                }
            })
            UsersAgentUsers({ page: 1, limit: 999 }).then(res => {
                if (res.code == 2000) {
                    this.agentList = res.data.data
                }
            })
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData.id = item.id
            }
            this.getBindData()
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        mmgjDeviceEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }
                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

