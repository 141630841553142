<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" top="15px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="门店名称：" prop="shop_name">
                    <el-input v-model.trim="formData.shop_name"></el-input>
                </el-form-item>
                <el-form-item label="地址：" required>
                    <div style="width: 100%;display: flex;">
                        <el-form-item prop="adcode">
                            <el-cascader :show-all-levels="true" style="width: 260px;" :options="areaList" separator=""
                                v-model="formData.adcode" @change="handleChange" ref="areaSelect"
                                :props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
                                filterable></el-cascader>
                        </el-form-item>
                        <el-form-item prop="place" style="width: 100%;">
                            <el-input v-model.trim="formData.place" placeholder="详细地址"></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="营业执照：" prop="business_license">
                    <singlepicture v-model="formData.business_license" :width="100" :height="100">
                    </singlepicture>
                </el-form-item>
                <el-form-item label="身份证：" required>
                    <el-space wrap :size="8">
                        <el-form-item prop="idcard_f">
                            <singlepicture v-model="formData.idcard_f" :width="100" :height="100" title="人像面">
                            </singlepicture>
                        </el-form-item>
                        <el-form-item prop="idcard_b">
                            <singlepicture v-model="formData.idcard_b" :width="100" :height="100" title="国徽面">
                            </singlepicture>
                        </el-form-item>
                    </el-space>
                </el-form-item>
                <el-form-item label="门头照：" prop="doorstep_photo">
                    <mutiplepictures v-model="formData.doorstep_photo" width="100px" height="100px"></mutiplepictures>
                </el-form-item>
                <el-form-item label="公司名称：" prop="company_name">
                    <el-input v-model.trim="formData.company_name"></el-input>
                </el-form-item>
                <el-form-item label="姓名：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="代理商编号：" prop="belong_agent_id">
                    <el-input v-model.trim="formData.belong_agent_id"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="mobile">
                    <el-input v-model.trim="formData.mobile"></el-input>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model.trim="formData.password"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { mmgjShopAdd, mmgjShopEdit, getAllAreaslist } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import mutiplepictures from "@/components/upload/mutiplepictures";
import singlepicture from "@/components/upload/singlepicture";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog, mutiplepictures, singlepicture },
    emits: ['refreshData', 'closed'],
    name: "moduleShop",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                shop_name: '',
                doorstep_photo: [],
                areaname: '',
            },
            rules: {
                shop_name: [
                    { required: true, message: '请填写门店名称', trigger: 'blur' }
                ],
                adcode: [
                    { required: true, message: '请选择地区', trigger: 'blur' }
                ],
                place: [
                    { required: true, message: '请填写门店地址', trigger: 'blur' }
                ],
                business_license: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' }
                ],
                idcard_f: [
                    { required: true, message: '请上传身份证正面', trigger: 'blur' }
                ],
                idcard_b: [
                    { required: true, message: '请上传身份证反面', trigger: 'blur' }
                ],
                company_name: [
                    { required: true, message: '请填写公司名称', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' }
                ],
                belong_agent_id: [
                    { required: true, message: '请填写代理商编号', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请填写手机号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ],
            },
            areaList: [],
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        getAllAreaslistData() {
            getAllAreaslist({ subdistrict: 2 }).then(res => {
                if (res.code === 2000) {
                    this.areaList = res.data.data
                }
            })
        },
        handleChange(e) {
            this.formData.areaname = this.$refs.areaSelect.getCheckedNodes()[0].text
            // this.areaList.forEach(item => {
            //     if (!!item.childlist && item.childlist.length > 0) {
            //         item.childlist.forEach(itemn => {
            //             if (!!itemn.childlist && itemn.childlist.length > 0) {
            //                 itemn.childlist.forEach(iteme => {
            //                     if (iteme.id == e) {
            //                         this.formData.areaname = item.name + itemn.name + iteme.name
            //                     }
            //                 })
            //             }
            //         })
            //     }
            // })
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.getAllAreaslistData()
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        mmgjShopEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        mmgjShopAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

