<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="资讯类型：" prop="type">
                    <el-select size="default" v-model="formData.type" placeholder="请选择" clearable filterable
                        style="width: 100%">
                        <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题：" prop="title">
                    <el-input v-model.trim="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="封面图：" prop="cover">
                    <LyUploadAvatar v-model="formData.cover" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
                <el-form-item label="正文：" prop="content">
                    <TEditor v-model="formData.content"></TEditor>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { mmgjNewsAdd, mmgjNewsEdit, mmgjNewsType } from "@/api/api";
import TEditor from '@/components/TEditor'
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog, TEditor, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    name: "moduleNews",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                type: '',
                title: '',
                content: '',
                cover: '',
            },
            rules: {
                type: [
                    { required: true, message: '请选择类型', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: '请填写标题', trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请填写内容', trigger: 'blur' }
                ],
                cover: [
                    { required: true, message: '请上传封面图', trigger: 'blur' }
                ]
            },
            typeList: []
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.getNewsType()
            if (item) {
                this.formData = deepClone(item)
            }
        },
        getNewsType() {
            mmgjNewsType({ page: 1, limit: 999, status: 1 }).then(res => {
                if (res.code == 2000) {
                    this.typeList = res.data.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        mmgjNewsEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        mmgjNewsAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

