<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="名称：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" style="width: 100%;"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { mmgjNewsTypeAdd, mmgjNewsTypeEdit } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog },
    emits: ['refreshData'],
    name: "moduleNewsTypeManage",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                sort: 1,
            },
            rules: {
                name: [
                    { required: true, message: '请填写名称', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                name: '',
                sort: 1,
            }
        },
        addModuleFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        mmgjNewsTypeEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        mmgjNewsTypeAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

