<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="门店名称：">
                    <el-input v-model.trim="formInline.shop_name" maxlength="60" clearable placeholder="门店名称"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="搜索：">
                    <el-input v-model.trim="formInline.name_mobile" maxlength="60" clearable placeholder="搜索" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="身份：">
                    <el-select v-model="formInline.type" placeholder="请选择" clearable style="width: 90px" @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- 
                <el-form-item label="时间：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <!-- <el-form-item label=""><el-button icon="Plus" @click="handleAdd" type="primary"
                        v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item> -->
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" v-loading="loadingPage" ref="tableref"
                style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange">
                <!-- <el-table-column type="selection" width="60" align="center"></el-table-column>
                <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column width="60" prop="creator" label="ID"></el-table-column>
                <el-table-column width="90" prop="name" label="姓名"></el-table-column>
                <el-table-column width="100" label="身份">
                    <template #default="scope">
                        {{ scope.row.type == 1 ? "店长" : "店员" }}
                    </template>
                </el-table-column>
                <el-table-column width="130" prop="mobile" label="手机号"></el-table-column>
                <el-table-column min-width="100" prop="shop_name" label="门店名称" show-overflow-tooltip></el-table-column>
                <el-table-column min-width="150" label="门店地址" show-overflow-tooltip>
                    <template #default="scope">
                        {{ scope.row.areaname }}
                    </template>
                </el-table-column>
                <el-table-column width="100" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column width="170" prop="create_datetime" label="注册时间"></el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-module ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible"
            @closed="isDialogVisible = false"></add-module>
        <moduleShop ref="addModuleShopFlag" v-if="isDialogShopVisible" @closed="isDialogShopVisible = false"></moduleShop>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats } from "@/utils/util";
import { mmgjShop, mmgjShopDelete, mmgjShopDisable } from '@/api/api'
import AddModule from "./components/addModuleShopRecharge";
import moduleShop from "./components/moduleShop";
import { lyMixins } from "@/mixins/mixins"
export default {
    mixins: [lyMixins],
    components: {
        AddModule,
        Pagination,
        moduleShop
    },
    name: 'shopStaffManage',
    data() {
        return {
            isFull: false,
            loadingPage: false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            defaultImg: require('../../assets/img/avatar.jpg'),
            formInline: {
                page: 1,
                limit: 10,
                audit_status: 20,
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            auditList: [
                { id: 10, name: '审核中' },
                { id: 20, name: '审核通过' },
                { id: 30, name: '审核拒绝' },
            ],
            statusList: [
                { id: 1, name: '店长' },
                { id: 2, name: '店员' }
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
            isDialogShopVisible: false,
        }
    },
    methods: {
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        changeStatus(row) {
            let vm = this
            let flat = row.status
            row.status = !row.status
            vm.$confirm('确定修改状态吗？', '提醒', {
                closeOnClickModal: false,
                type: 'warning'
            }).then(res => {
                mmgjShopDisable({ id: row.id }).then(res => {
                    if (res.code == 2000) {
                        flat ? row.status = true : row.status = false
                        vm.$message.success(res.msg)
                        vm.getData()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        //多选项框被选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        /** 批量删除按钮操作 */
        handleDelete(row) {
            const ids = this.ids;
            let vm = this
            vm.$confirm('是否确认删除选中的数据项?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return mmgjShopDelete({ id: ids }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.search()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            })
        },
        handleAdd() {
            this.isDialogShopVisible = true
            this.$nextTick(() => {
                this.$refs.addModuleShopFlag.handleOpen(null, "新增")
            })
        },
        handleEdit(row, flag) {
            if (flag == 'recharge') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleFlag.handleOpen(row, "充值卡点")
                })
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的分类？', {
                    closeOnClickModal: false
                }).then(res => {
                    mmgjShopDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10,
                    audit_status: 20,
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.getData()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            mmgjShop(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
    },
    created() {
        this.getData()
    },
    timers(val) {
        if (val) {
            this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
            this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
        } else {
            this.formInline.beginAt = ''
            this.formInline.endAt = ''
        }
        this.getData()
    },
}
</script>
