<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="设备编号：">
                    <el-input v-model.trim="formInline.sn" maxlength="60" clearable placeholder="设备编号" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="绑定状态：">
                    <el-select v-model="formInline.status" placeholder="请选择" clearable style="width: 90px" @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="代理商：">
                    <el-input v-model.trim="formInline.belong_agent_name" maxlength="60" clearable placeholder="代理商"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <!--                <el-form-item label="创建时间：">-->
                <!--                    <el-date-picker-->
                <!--                            style="width:100% !important;"-->
                <!--                            v-model="timers"-->
                <!--                            size="small"-->
                <!--                            type="datetimerange"-->
                <!--                            @change="timeChange"-->
                <!--                            range-separator="至"-->
                <!--                            start-placeholder="开始日期"-->
                <!--                            end-placeholder="结束日期">-->
                <!--                    </el-date-picker>-->
                <!--                </el-form-item>-->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button size="default" icon="Plus" @click="handelAddClick" type="primary"
                        v-show="hasPermission(this.$route.name, 'Create')">生成设备编号</el-button></el-form-item>
                <el-form-item label="" v-if="hasPermission(this.$route.name, 'Export') && true"><el-button type="primary"
                        @click="exportDataBackend" :disabled="loadingPage" icon="Download">导出</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="'calc(' + (tableHeight) + 'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage"
            style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column width="60" prop="id" label="ID"></el-table-column>
            <el-table-column width="200" prop="sn" label="设备编号"></el-table-column>
            <el-table-column width="130" prop="status" label="是否与设备绑定">
                <template #default="scope">
                    <el-tag type="success" v-if="scope.row.status">已绑定
                    </el-tag>
                    <el-tag type="warning" v-else>未绑定
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column min-width="130" prop="belong_agent_name" label="代理商"></el-table-column>
            <el-table-column min-width="130" prop="shop_name" label="门店名称"></el-table-column>
            <el-table-column min-width="180" prop="place" label="设备定位"></el-table-column>
            <!-- <el-table-column min-width="180" prop="create_datetime" label="创建时间"></el-table-column> -->
            <el-table-column label="操作" fixed="right" width="200">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                        v-show="hasPermission(this.$route.name, 'Update') && !scope.row.status">关联门店</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'unedit')"
                        v-show="hasPermission(this.$route.name, 'Update') && scope.row.status">解除关联门店</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'tiyanlist')" v-show="hasPermission(this.$route.name, 'TiyanList')">体验明细</span>
                    <!-- <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                        v-show="hasPermission(this.$route.name, 'Delete')">删除</span> -->
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addModule ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addModule>
        <DialogTableList ref="moduleTableFlag" :apiObj="apiObj" width="60%" :tableIndex="false" v-if="isDialogTYVisible"
            @closed="isDialogTYVisible = false">
            <el-table-column width="100" label="客户类型" fixed="left">
                <template #default="scope">
                    {{ scope.row.type == 1 ? "线下用户" : "线上用户" }}
                </template>
            </el-table-column>
            <el-table-column width="100" prop="customer_info.name" label="顾客姓名"></el-table-column>
            <el-table-column width="70" prop="customer_info.gender" label="性别"></el-table-column>
            <el-table-column width="70" prop="customer_info.age" label="年龄"></el-table-column>
            <el-table-column width="120" prop="device_info.sn" label="设备编号"></el-table-column>
            <el-table-column width="100" prop="creator_name" label="店员姓名"></el-table-column>
            <el-table-column width="100" prop="experience_parts" label="体验部位"></el-table-column>
            <el-table-column min-width="130" prop="remark" label="备注"></el-table-column>
            <el-table-column min-width="170" prop="create_datetime" label="体验日期"></el-table-column>
        </DialogTableList>
    </div>
</template>
<script>
import addModule from "./components/moduleDevice";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { mmgjDevice, mmgjDeviceAdd, mmgjDeviceExport, mmgjDeviceEdit,mmgjOrder } from '@/api/api'
import DialogTableList from "@/components/dialog/dialogTableList"
export default {
    components: {
        Pagination,
        addModule,
        DialogTableList
    },
    name: 'deviceManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '已绑定' },
                { id: 0, name: '未绑定' }
            ],
            tableData: [],
            isDialogVisible: false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            isDialogTYVisible:false,
            apiObj: mmgjOrder,
        }
    },
    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //当渲染的文字超出10字后显示省略号
        customEllipsis(value) {
            value = value.replace(/<.*?>/ig, "")       //把v-html的格式标签替换掉
            if (!value) return ""
            if (value.length > 10) {
                return value.slice(0, 10) + "..."
            }
            return value
        },
        handelAddClick() {
            let vm = this
            vm.$prompt('批量生成设备编号（请输入生成数量）',"提示",{
                closeOnClickModal:false,
                type: 'warning'
            }).then(({ value })=>{
                value = parseInt(value)
                if(this.isInteger(value)){
                    mmgjDeviceAdd({nums:value}).then(res => {
                        if (res.code === 2000) {
                            vm.$message.success("新增成功")
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }else{
                    vm.$message.warning("请输入正确的数量")
                }
                
            }).catch(()=>{
                vm.$message.warning("请输入正确的数量")
            })
        },
        isInteger(value) {
            return Number.isInteger(value); // 使用 JavaScript 的 Number.isInteger 方法来检查是否为整数
        },
        handleEdit(row, flag) {
            let vm = this
            if (flag == 'tiyanlist') {
                this.isDialogTYVisible = true
                this.$nextTick(() => {
                    this.device_id = row.id
                    let params = { is_tiyan: 1, device_id: this.device_id }
                    if (!!this.formInline.beginAt) {
                        params.beginAt = this.formInline.beginAt
                        params.endAt = this.formInline.endAt
                    }
                    this.$refs.moduleTableFlag.handleOpen(params, "体验明细")
                })
            }
            else if (flag == 'edit') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleFlag.handleOpen(row, "关联门店")
                })
            }
            else if (flag == 'unedit') {
                vm.$confirm('您确定要与门店解除关联吗？', '警告', {
                    closeOnClickModal: false,
                    type: "warning"
                }).then(res => {
                    mmgjDeviceEdit({ id: row.id, bind: 0 }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            mmgjDevice(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        setLoadingPage(bools) {
            this.loadingPage = bools
        },
        //导出
        exportDataBackend() {
            let params = {}
            if (this.ids.length > 0) {
                params = {
                    ids: this.ids,
                }
            }
            this.setLoadingPage(true)
            mmgjDeviceExport(params).then(res => {
                this.setLoadingPage(false)
                this.downloadExcelBlob(res)
            })
        },
        //excel文件流下载
        downloadExcelBlob(res) {
            let fileName = new Date().getTime() + ".xlsx"
            let dispositionStr = res.headers["content-disposition"];
            if (dispositionStr == null || dispositionStr === "") {

            } else {
                // 获取文件名
                let dispositionArr = dispositionStr.split(";");
                fileName = decodeURIComponent(dispositionArr[1]);
                fileName = fileName.split("=")[1];
            }
            const blob = new Blob([res.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let href = window.URL.createObjectURL(blob); //下载链接
            let link = document.createElement("a")
            link.href = href
            link.download = fileName //下载后文件名
            document.body.appendChild(link);
            link.click(); //点击下载
            document.body.removeChild(link); //下载完成移除元素
            window.URL.revokeObjectURL(href);  //释放blob对象
            this.$message.success('导出成功')
        },

        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }
    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },
    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
