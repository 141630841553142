<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="580px" :before-close="handleClose"
            :fullscreen="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
                <el-form-item label="代理商剩余卡点：">
                    {{ currentBalance }}
                </el-form-item>
                <el-form-item label="划拨卡点数：" prop="amount">
                    <el-input-number v-model.trim="formData.amount" clearable placeholder="请输入" :controls="false"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { systemUserUserInfo, mmgjShopTransfer } from "@/api/api"
export default {
    emits: ['refreshData', 'closed'],
    name: "addModuleShopRecharge",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                id: "",
                amount: null,
            },
            rules: {
                amount: [
                    { required: true, message: '请输入充值卡点数', trigger: 'blur' }
                ],
            },
            currentBalance: 0
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData.id = item.id
                this.getCurrentUserInfo()
            }
        },
        /**
             * 获取当前用户信息
             */
        getCurrentUserInfo() {
            systemUserUserInfo().then(res => {
                if (res.code == 2000) {
                    this.currentBalance = res.data.data.balance
                }

            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    mmgjShopTransfer(this.formData).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })

                }
            })
        }

    }
}
</script>

