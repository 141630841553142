<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="580px" :before-close="handleClose"
            :fullscreen="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
                <div class="ly-title">
                    店铺信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="加盟店ID：">
                            {{ formData.id }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="加盟店名称：">
                            {{ formData.shop_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地区：">
                            {{ formData.areaname }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属代理商：">
                            {{ formData.belong_agent_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="代理商编号：">
                            {{ formData.belong_agent_id }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="剩余卡点：">
                            {{ formData.balance }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="已用卡点：">
                            {{ formData.used_amount }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="营业执照：">
                            <el-image :src=formData.business_license :preview-src-list="[formData.business_license]"
                                preview-teleported style="width: 45px;height: 45px"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="门店照片：">
                            <el-image v-for="item in formData.doorstep_photo" :src=item.url :preview-src-list="[item.url]"
                                preview-teleported style="width: 45px;height: 45px;margin-right: 10px;"></el-image>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title">
                    申请人信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="公司名称：">
                            {{ formData.company_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="法人姓名：">
                            {{ formData.name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="手机号：">
                            {{ formData.mobile }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { mmgjShopAudit } from "@/api/api"
export default {
    emits: ['refreshData', 'closed'],
    name: "addModuleShopAudit",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            peopleList: [],
            formData: {
                action: 10,
                audit_remarks: "",
            },
            rules: {
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

