<template>
    <div class="lycontainer">
        <el-scrollbar>
            <el-row :gutter="20">
                <el-col :span="8" :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 8 }" v-for="item in growData">
                    <div class="space-inner" @click="handleRoute(item.route)">
                        <div class="lycard">
                            <div class="space-header">
                                <div class="space-header-title">{{ item.title }}</div>
                            </div>
                            <el-skeleton :rows="4" :animated="false" :count="1" :loading="false"
                                style="padding: 20px;width: auto;overflow: hidden;">
                                <template #default>
                                    <div class="space-main">
                                        <div class="space-main-up">
                                            <span style="font-size: 24px;">{{ item.nums }}</span>
                                            <!-- <div class="space-main-icon">
                                        <el-avatar :size="50" :icon="item.icon.type"
                                            :style="{ background: item.icon.background ? item.icon.background : null }" />
                                    </div> -->
                                        </div>
                                    </div>
                                </template>
                            </el-skeleton>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-scrollbar>
    </div>
</template>

<script>
import LyGrowcard from "@/components/analysis/growCard";
import { mmgjIndexmain } from "@/api/api"
export default {
    name: "indexMain",
    components: { LyGrowcard },
    data() {
        return {
            growData: [
                {
                    id: 1, title: "加盟店总数", nums: 0, icon: {
                        type: "View",
                        background: "#67c23a",
                    }, route: { name: 'shopStatisticsTotal' }
                },
                {
                    id: 2, title: "店员总数", nums: 0, icon: {
                        type: "GoodsFilled",
                        background: "#e6a23c",
                    }, route: { name: 'clerkStatisticsTotal' }
                },
                {
                    id: 3, title: "顾客总数", nums: 0, icon: {
                        type: "Download",
                        background: "#409eff",
                    }, route: { name: 'customerStatisticsTotal' }
                },
                {
                    id: 4, title: "体验总数", nums: 0, icon: {
                        type: "WalletFilled",
                        background: "#f56c6c",
                    }, route: { name: 'experiencePartsStatisticsTotal' }
                },
                {
                    id: 4, title: "设备总数", nums: 0, icon: {
                        type: "WalletFilled",
                        background: "#f56c6c",
                    }, route: { name: 'devicesStatisticsTotal' }
                },
            ],
        }
    },
    methods: {
        getData() {
            mmgjIndexmain().then(res => {
                if (res.code === 2000) {
                    this.growData[0].nums = res.data.shop_count
                    this.growData[1].nums = res.data.shop_user_count
                    this.growData[2].nums = res.data.customer_count
                    this.growData[3].nums = res.data.tiyan_count
                    this.growData[4].nums = res.data.device_count
                }
            })
        },
        handleRoute(params) {
            this.$router.push(params)
        }
    },
    created() {
        this.getData()
    },
}
</script>
<style lang="scss" scoped>
.lycontainer {
    width: 100%;
    /*overflow-x: hidden;*/
    /*overflow-y:auto;*/
}

.echarts-inner {
    margin-top: 1px;
}

::v-deep(.el-scrollbar__bar.is-horizontal) {
    display: none;
}

.space-inner {
    cursor: pointer;
}

.lycard {
    background: var(--el-bg-color);
    box-shadow: var(--el-box-shadow-light);
    border: 1px solid var(--el-border-color-light);
}

.space-header {
    border-bottom: 1px solid var(--el-color-info-light-7);
    font-size: 14px;
    padding: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.space-header-title {
    padding: 8px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.space-header-extend-right {
    padding: 8px 0;
    margin-right: 10px;
}

.space-main {
    padding: 20px;
}

.space-main-icon {}

.space-main-up {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.space-main-down {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

::v-deep(.el-col) {
    margin-bottom: 11px;
}
</style>