<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="姓名：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="姓名" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="代理商：">
                    <el-input v-model.trim="formInline.belong_agent_name" maxlength="60" clearable placeholder="代理商"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="门店名称：">
                    <el-input v-model.trim="formInline.shop_name" maxlength="60" clearable placeholder="门店名称"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="身份：">
                    <el-select v-model="formInline.type" placeholder="请选择" clearable style="width: 90px" @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="时间范围：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>店员总数：{{ pageparm.total }}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column min-width="70" prop="avatar" label="用户头像">
                <template #default="scope">
                    <img :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg"
                        style="width: 30px;height: 30px" :onerror="defaultImg">
                </template>
            </el-table-column> -->
            <el-table-column min-width="120" prop="belong_agent_name" label="代理商"></el-table-column>
            <!-- <el-table-column width="110" prop="id" label="ID"></el-table-column> -->
            <el-table-column min-width="120" prop="shop_name" label="门店名称" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column min-width="150" prop="areaname" label="地区" show-overflow-tooltip></el-table-column> -->
            <el-table-column width="90" prop="name" label="姓名"></el-table-column>
            <el-table-column width="100" label="身份">
                <template #default="scope">
                    {{ scope.row.type == 1 ? "店长" : "店员" }}
                </template>
            </el-table-column>
            <el-table-column min-width="130" prop="mobile" label="手机号"></el-table-column>
            <el-table-column min-width="130" prop="start_device_nums" label="累计开设备总数"></el-table-column>
            <el-table-column min-width="130" prop="service_user_nums" label="服务用户总数"></el-table-column>
            <el-table-column min-width="170" prop="last_login" label="最后登录时间"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { dateFormats } from "@/utils/util";
import { mmgjShop } from '@/api/api'
import { lyMixins } from "@/mixins/mixins"
export default {
    mixins: [lyMixins],
    components: {
        Pagination,
    },
    name: 'clerkStatistics',
    data() {
        return {
            isFull: false,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
                audit_status: 20,
                is_tongji: 1,
                is_dytj: 1,
            },
            orderstatics: {
                agentCount: 0,
                agentAmount: 0,
                shopCount: 0,
                shopAmount: 0
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            tableData: [],
            timers: [],
            statusList: [
                { id: 1, name: '店长' },
                { id: 2, name: '店员' }
            ],
        }
    },
    methods: {
        setFull() {
            this.isFull = !this.isFull
        },
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        addModule() {
            this.$refs.addModuleFlag.addModuleFn(null, '新增', '1')
        },
        handleEdit(row, flag) {
            let vm = this
            if (flag == 'detail') {
                vm.$refs.addModuleFlag.addModuleFn(row, '详情', '1')
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10,
                    audit_status: 20,
                    is_tongji: 1,
                    is_dytj: 1,
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.search()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            mmgjShop(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFile(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportData() {
            // var params = {
            //     type:3,
            //     page: 1,
            //     limit: 9999,
            // }
            // retrieveFinancestatisticsExport(params).then(res => {
            //      if(res.code ==2000) {
            //          this.downloadFile(res.data.data)
            //          //this.$message.warning(res.data.data)
            //      }
            //  })
        }
    },
    created() {
        if (!!this.$route.query.name) {
            this.formInline.belong_agent_name = this.$route.query.name
        }
        if (!!this.$route.query.shop_name) {
            this.formInline.shop_name = this.$route.query.shop_name
        }
        this.getData()
    },
}
</script>
<style lang="scss">
.order-static {
    display: flex;
    font-size: 14px;
    background: var(--l-order-statics);
    padding: 10px;

    li {
        margin-right: 30px;

    }
}
</style>