<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="姓名：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="姓名" @change="search"
                        style="width:150px"></el-input>
                </el-form-item>
                <el-form-item label="加盟店：">
                    <el-input v-model.trim="formInline.shop_name" maxlength="60" clearable placeholder="加盟店"
                        @change="search" style="width:150px"></el-input>
                </el-form-item>
                <!-- <el-form-item label="时间范围：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>顾客总数：{{ pageparm.total }}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <!-- <el-table-column min-width="70" prop="avatar" label="用户头像">
                <template #default="scope">
                    <img :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg"
                        style="width: 30px;height: 30px" :onerror="defaultImg">
                </template>
            </el-table-column> -->
            <el-table-column width="120" prop="belong_agent_name" label="代理商"></el-table-column>
            <el-table-column min-width="100" prop="shop_name" label="门店名称" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column min-width="150" prop="areaname" label="客户类型">
                <template #default="scope">
                    {{ scope.row.type == 1 ? "店长" : "店员" }}
                </template>
            </el-table-column> -->
            <el-table-column width="90" prop="name" label="姓名"></el-table-column>
            <el-table-column width="90" prop="gender" label="性别"></el-table-column>
            <el-table-column width="130" prop="mobile" label="手机号"></el-table-column>
            <el-table-column width="90" prop="age" label="年龄"></el-table-column>
            <el-table-column width="100" prop="balance" label="剩余卡点">
                <template #default="scope">
                    {{ parseInt(scope.row.balance).toFixed(0) }}
                </template>
            </el-table-column>
            <el-table-column min-width="180" prop="last_experience_time" label="最近体验日期"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" label="注册日期"></el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'tiyanlist')"
                        v-show="hasPermission(this.$route.name, 'TiyanList')">体验明细</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'record')"
                        v-show="hasPermission(this.$route.name, 'RechargeList')">充值记录</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <DialogTableList ref="moduleTableFlag" :apiObj="apiObj" width="60%" :tableIndex="false" v-if="isDialogVisible"
            @closed="isDialogVisible = false">
            <el-table-column width="100" label="客户类型" fixed="left">
                <template #default="scope">
                    {{ scope.row.type == 1 ? "线下用户" : "线上用户" }}
                </template>
            </el-table-column>
            <el-table-column width="100" prop="customer_info.name" label="顾客姓名"></el-table-column>
            <el-table-column width="70" prop="customer_info.gender" label="性别"></el-table-column>
            <el-table-column width="70" prop="customer_info.age" label="年龄"></el-table-column>
            <el-table-column width="120" prop="device_info.sn" label="设备编号"></el-table-column>
            <el-table-column width="100" prop="creator_name" label="店员姓名"></el-table-column>
            <el-table-column width="100" prop="experience_parts" label="体验部位"></el-table-column>
            <el-table-column min-width="130" prop="remark" label="备注"></el-table-column>
            <el-table-column min-width="170" prop="create_datetime" label="体验日期"></el-table-column>
        </DialogTableList>
        <DialogTableList ref="moduleTableFlag" :apiObj="apiObjRg" width="60%" :tableIndex="false" v-if="isDialogRgVisible"
            @closed="isDialogRgVisible = false">
            <el-table-column mn-width="130" prop="money" label="充值卡点">
                <template #default="scope">
                    {{ parseInt(scope.row.money) }}
                </template>
            </el-table-column>
            <el-table-column min-width="150" prop="operate_user" label="操作人员"></el-table-column>
            <el-table-column min-width="150" prop="operate_user_type_name" label="人员类型"></el-table-column>
            <el-table-column min-width="170" prop="create_datetime" label="充值日期"></el-table-column>
        </DialogTableList>
    </div>
</template>
<script>

import Pagination from "@/components/Pagination";
import { dateFormats } from "@/utils/util";
import { mmgjCustomer, mmgjOrder, mmgjCustomerBalance } from '@/api/api'
import { lyMixins } from "@/mixins/mixins"
import DialogTableList from "@/components/dialog/dialogTableList"

export default {
    mixins: [lyMixins],
    components: {
        Pagination,
        DialogTableList
    },
    name: 'customerStatisticsTotal',
    data() {
        return {
            isFull: false,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
                audit_status: 20,
                type: 1,
                is_tongji: 1,
            },
            orderstatics: {
                agentCount: 0,
                agentAmount: 0,
                shopCount: 0,
                shopAmount: 0
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            tableData: [],
            timers: [],
            statusList: [
                { id: 1, name: '店长' },
                { id: 2, name: '店员' }
            ],
            isDialogVisible: false,
            apiObj: mmgjOrder,
            shop_id: "",
            isDialogRgVisible: false,
            apiObjRg: mmgjCustomerBalance,
        }
    },
    methods: {
        setFull() {
            this.isFull = !this.isFull
        },
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        handleEdit(row, flag) {
            if (flag == 'tiyanlist') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.customer_id = row.id
                    this.$refs.moduleTableFlag.handleOpen({ is_tiyan: 1, customer_id: row.id }, "体验明细")
                })
            }
            else if (flag == "record") {
                this.isDialogRgVisible = true
                this.$nextTick(() => {
                    this.$refs.moduleTableFlag.handleOpen({ type: 1, user_type: 3, user_id: row.id }, "充值记录")
                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10,
                    audit_status: 20,
                    type: 1,
                    is_tongji: 1,
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.timers = []
                this.search()
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            mmgjCustomer(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFile(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportData() {
            // var params = {
            //     type:3,
            //     page: 1,
            //     limit: 9999,
            // }
            // retrieveFinancestatisticsExport(params).then(res => {
            //      if(res.code ==2000) {
            //          this.downloadFile(res.data.data)
            //          //this.$message.warning(res.data.data)
            //      }
            //  })
        }
    },
    created() {
        if (!!this.$route.query.shop_name) {
            this.formInline.shop_name = this.$route.query.shop_name
        }
        this.getData()
    },
}
</script>
<style lang="scss">
.order-static {
    display: flex;
    font-size: 14px;
    background: var(--l-order-statics);
    padding: 10px;

    li {
        margin-right: 30px;

    }
}
</style>