<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" top="15px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto" :disabled="loadingTitle == '详情'">
                <el-form-item label="代理商名称：" prop="name">
                    <el-input v-model.trim="formData.name"></el-input>
                </el-form-item>
                <el-form-item label="地址：" required>
                    <div style="width: 100%;display: flex;">
                        <el-form-item prop="adcode">
                            <el-cascader :show-all-levels="true" style="width: 260px;" :options="areaList" separator=""
                                v-model="formData.adcode" @change="handleChange" ref="areaSelect"
                                :props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
                                filterable></el-cascader>
                        </el-form-item>
                        <el-form-item prop="place" style="width: 100%;">
                            <el-input v-model.trim="formData.place" placeholder="详细地址"></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="负责人姓名：" prop="leader_name">
                    <el-input v-model.trim="formData.leader_name"></el-input>
                </el-form-item>
                <el-form-item label="负责人电话：" prop="leader_mobile">
                    <el-input v-model.trim="formData.leader_mobile"></el-input>
                </el-form-item>
                <el-form-item label="营业执照：" prop="business_license">
                    <singlepicture v-model="formData.business_license" :width="100" :height="100">
                    </singlepicture>
                </el-form-item>
                <el-form-item label="账号：" prop="username">
                    <el-input v-model.trim="formData.username"></el-input>
                </el-form-item>
                <el-form-item label="密码：" prop="password">
                    <el-input v-model.trim="formData.password"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave"
                    v-if="loadingTitle != '详情'">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { UsersAgentUsersAdd, UsersAgentUsersEdit, getAllAreaslist } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import singlepicture from "@/components/upload/singlepicture";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog, singlepicture },
    emits: ['refreshData', 'closed'],
    name: "addUserAgent",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                shop_name: '',
                areaname: '',
                adcode: "",
                place: "",
            },
            rules: {
                name: [
                    { required: true, message: '请填写代理商名称', trigger: 'blur' }
                ],
                adcode: [
                    { required: true, message: '请选择地区', trigger: 'blur' }
                ],
                place: [
                    { required: true, message: '请填写门店地址', trigger: 'blur' }
                ],
                business_license: [
                    { required: true, message: '请上传营业执照', trigger: 'blur' }
                ],
                leader_name: [
                    { required: true, message: '请填写负责人姓名', trigger: 'blur' }
                ],
                leader_mobile: [
                    { required: true, message: '请填写负责人电话', trigger: 'blur' }
                ],
                username: [
                    { required: true, message: '请填写账号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请填写密码', trigger: 'blur' }
                ],
            },
            areaList: [],
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        getAllAreaslistData() {
            getAllAreaslist({ subdistrict: 2 }).then(res => {
                if (res.code === 2000) {
                    this.areaList = res.data.data
                }
            })
        },
        handleChange(e) {
            this.formData.areaname = this.$refs.areaSelect.getCheckedNodes()[0].text
            // this.areaList.forEach(item => {
            //     if (!!item.childlist && item.childlist.length > 0) {
            //         item.childlist.forEach(itemn => {
            //             if (!!itemn.childlist && itemn.childlist.length > 0) {
            //                 itemn.childlist.forEach(iteme => {
            //                     if (iteme.id == e) {
            //                         this.formData.areaname = item.name + itemn.name + iteme.name
            //                     }
            //                 })
            //             }
            //         })
            //     }
            // })
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.getAllAreaslistData()
            if (item) {
                let tempData = deepClone(item)
                this.formData.id = tempData.id
                this.formData.name = tempData.name
                this.formData.username = tempData.username
                this.formData.password = tempData.userinfo.password
                this.formData.business_license = tempData.userinfo.business_license
                this.formData.leader_name = tempData.userinfo.leader_name
                this.formData.leader_mobile = tempData.userinfo.leader_mobile
                this.formData.adcode = tempData.userinfo.adcode
                this.formData.areaname = tempData.userinfo.areaname
                this.formData.place = tempData.userinfo.place
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        UsersAgentUsersEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        UsersAgentUsersAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

